
import React, { Suspense, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Gallery from './gallery/Gallery';
import { startTypewriterEffect } from './typewriter/Typewriter';
import { Environment } from '@react-three/drei';
import { Canvas, useThree, useFrame } from '@react-three/fiber';

const Model = () => {
  const { scene, camera } = useThree();
  const modelRef = useRef();
  const { viewport } = useThree();
  const isDragging = useRef(false);
  const initialMouseX = useRef(0);
  const canvasRef = useRef();
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [zoomSpeed, setZoomSpeed] = useState(0.1);

  const isPanning = useRef(false);
  const lastMousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleMouseDown = (event) => {
      if (event.button === 1) {
        isPanning.current = true;
        lastMousePosition.current = { x: event.clientX, y: event.clientY };
        event.preventDefault();
      } else {
        isDragging.current = true;
        initialMouseX.current = event.clientX;
      }
    };

    const handleMouseUp = (event) => {
      if (event.button === 1) {
        isPanning.current = false;
      } else {
        isDragging.current = false;
      }
    };
    
    const handleMouseMove = (event) => {
      if (isPanning.current) {
        const deltaX = (event.clientX - lastMousePosition.current.x) * 0.01;
        const deltaY = (event.clientY - lastMousePosition.current.y) * 0.01;
  
        camera.position.x -= deltaX;
        camera.position.y += deltaY;
  
        lastMousePosition.current = { x: event.clientX, y: event.clientY };
      } else if (isDragging.current) {
        const rotationSpeed = 0.15;
        const deltaX = event.clientX - initialMouseX.current;
        const rotationAngle = (deltaX / viewport.width) * rotationSpeed;
  
        modelRef.current.rotation.y += rotationAngle;
        initialMouseX.current = event.clientX;
      }
    };

    const handleWheel = (event) => {
      const delta = event.deltaY;

      const scaleChange = delta > 0 ? -zoomSpeed : zoomSpeed;
      const newScale = modelRef.current.scale.x + scaleChange;

      const minScale = 0.5;
      const maxScale = 4;
      const clampedScale = Math.max(minScale, Math.min(maxScale, newScale));

      modelRef.current.scale.set(clampedScale, clampedScale, clampedScale);

      const zoomFactor = 0.1;
      camera.position.y += delta > 0 ? -zoomFactor : zoomFactor;

      event.preventDefault();
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [viewport.width]);

  useFrame(() => {
    if (isDragging.current) {
      modelRef.current.rotation.y += 0;
    }
  });

  useEffect(() => {
    const loadModel = async () => {
      const loader = new GLTFLoader();
      const gltf = await loader.loadAsync('/UltraMan.glb');
      const model = gltf.scene;

      const scale = 2;
      model.scale.set(scale, scale, scale);

      modelRef.current = model;
      setIsModelLoaded(true);
    };

    loadModel();
  }, []);

  return <group position={[-.5, -2.5, 0]}>{isModelLoaded && <primitive object={modelRef.current} />}</group>;
};

const Home = ({ showModel, setShowModel }) => {
  const profileImg='/profile.png'
  const profileLink='https://www.linkedin.com/in/questmatthewsmartial/'

  useEffect(() => {
    const typewriterContainer = document.getElementById('typewriter-container');
    startTypewriterEffect(typewriterContainer);
  }, []);

  const handleNavigation = (route) => {
    if (route === '/vitruvian') {
      setShowModel(false);
      window.scrollTo(0, 0);
    } else {
      setShowModel(true);
    }
  };

  return (
    <div className="home">
      <a href={profileLink} target="_blank" rel="noopener noreferrer" className='profile'>
        <img src={profileImg} alt="Gallery item" />
        <span className='profile-text'>
          me and my wife!
        </span>
      </a>
      <div className="left-column">
        <div className="navigation">
          <Link to="/" className="button-link" style={{ paddingLeft: '20px' }}>
            Home
          </Link>
          &nbsp; | &nbsp; {}
          <a href="https://phi-vitruvian.co/" className="button-link" style={{ paddingLeft: '20px' }} target="_blank" rel="noopener noreferrer">
            Vitruvian
          </a>
        </div>

        <div className="typewriter-container" id="typewriter-container"></div>

        <div className="links">
          <a href="https://twitter.com/phiquest2358" target="_blank" rel="noopener noreferrer">
            Twitter
          </a>
          &nbsp; | &nbsp;
          <a href="https://www.artstation.com/o0i0o" target="_blank" rel="noopener noreferrer">
            Artstation
          </a>
          &nbsp; | &nbsp;
          <a href="https://sketchfab.com/o0i0o" target="_blank" rel="noopener noreferrer">
            Sketchfab
          </a>
          &nbsp; | &nbsp;
          <a href="https://www.linkedin.com/in/questmatthewsmartial/" target="_blank" rel="noopener noreferrer">
            Linkedin
          </a>
        </div>
        <div className="gallery">
          <Gallery /> {}
        </div>
      </div>
      <div className="right-column">
        <Suspense fallback={null}>
          <Canvas camera={{ position: [0, 0, 4.5] }}>
            <Environment preset="forest" />
            <ambientLight intensity={0.15} />
            <spotLight position={[10, 15, 10]} angle={0.3} intensity={0.3} />
            <Model />
          </Canvas>
        </Suspense>
      </div>
    </div>
  );
};

export default Home;

